<template>
    <div class="slide-up-2 box" color="light">
        
        <ion-row class="row">
            <ion-col size-md="6" size-xs="6">
                <!--Permissions card...-->
                <!--ion-label color="dark" style="font-size: 1.1em">App A Permissions</ion-label-->
                <ion-card class="ion-padding-horizontal ion-text-start permission" color="light" style="border: 0.1em solid">
                    <ion-row>
                        <ion-col size-md="8" size-xs="8" style="border-bottom: 0.1em solid var(--ion-color-medium)">
                            <div style="height: 0.7em" />
                            <ion-label>Username</ion-label>
                        </ion-col>
                        <ion-col size-md="4" size-xs="4" style="border-bottom: 0.1em solid var(--ion-color-medium)">
                            <div style="height: 0.8em" />
                            <ion-toggle aria-label="username" v-model="enableUsername" color="success" />
                        </ion-col>

                        <ion-col size-md="8" size-xs="8" style="border-bottom: 0.1em solid var(--ion-color-medium)">
                            <div style="height: 0.7em" />
                            <ion-label>Email</ion-label>
                        </ion-col>
                        <ion-col size-md="4" size-xs="4" style="border-bottom: 0.1em solid var(--ion-color-medium)">
                            <div style="height: 0.8em" />
                            <ion-toggle aria-label="email" v-model="enableEmail" color="success" />
                        </ion-col>

                        <ion-col size-md="8" size-xs="8">
                            <div style="height: 0.7em" />
                            <ion-label>Avatar</ion-label>
                        </ion-col>
                        <ion-col size-md="4" size-xs="4">
                            <div style="height: 0.8em" />
                            <ion-toggle aria-label="avatar" v-model="enableAvatar" color="success" />
                        </ion-col>
                    </ion-row>
                </ion-card>
            </ion-col>
            <ion-col size-md="6" size-xs="6">
                <!--Display card... -->
                <!--ion-label color="dark" style="font-size: 1.1em">App A Sees</ion-label-->
                <div style="margin-top: 1em">
                    <ion-card class="ion-padding app" color="light">
                        <ion-row>
                        <ion-col size-md="4" size-xs="4">
                            <ion-avatar style="width: 3em;height: 3em">
                                <img v-if="enableAvatar" src="https://th.bing.com/th/id/OIP.caDYUXEbyuWcptDGajC4fwAAAA?rs=1&pid=ImgDetMain" />
                                <img v-else src="https://th.bing.com/th/id/OIP.PoS7waY4-VeqgNuBSxVUogAAAA?rs=1&pid=ImgDetMain" />
                            </ion-avatar>
                        </ion-col>
                        <ion-col class="ion-text-start" size-md="8" size-xs="8">
                            <div style="height: 0.3em" />
                            <div id="username-check" style="color: var(--ion-color-warning);opacity: 0.9">
                                <ion-label v-if="enableUsername">John Doe</ion-label>
                                <ion-label v-else>Anonymous</ion-label>
                            </div>
                            <div id="email-check" style="color: var(--ion-color-success);font-size: 0.7em">
                                <ion-label v-if="enableEmail">johndoe@gmail.com</ion-label>
                                <ion-label v-else>******</ion-label>
                            </div>
                        </ion-col>
                        </ion-row>
                    </ion-card>
                </div>
            </ion-col>
        </ion-row>

        <!--Connection line... -->
        <div class="connection-lines">
            <div class="connection-line-1" style="height: 1em;" />
            <div class="connection-line-2" style="height: 1em;" />
            <div class="connection-line-3" style="height: 1em;" />
        </div>

    </div>
</template>

<script setup>
  import { ref } from 'vue'
  import { IonAvatar, IonCol, IonCard, IonLabel, IonRow, IonToggle } from '@ionic/vue';

  const enableAvatar = ref(true)
  const enableEmail = ref(true)
  const enableUsername = ref(true)
</script>

<style lang="css" scoped>
@keyframes spin2 {
  0% { border-bottom-color: #e2501d; }
  25% { border-bottom-color: #f79848; }
  50% { border-bottom-color: #fdb346; }
  75% { border-bottom-color: #f9cb2a; }
  100% { border-bottom-color: #ff7375; }
}

@keyframes spin1 {
  0% { border-bottom-color: #353651; }
  25% { border-bottom-color: #572a62; }
  50% { border-bottom-color: #9b4ad0; }
  75% { border-bottom-color: #cd5dbf; }
  100% { border-bottom-color: #d3a1d6; }
}

@keyframes spin {
  0% { border-bottom-color: #8bf0df; }
  25% { border-bottom-color: #8bf0c6; }
  50% { border-bottom-color: #12ebce; }
  75% { border-bottom-color: #08d5c4; }
  100% { border-bottom-color: #08b6d5; }
}

.app {
    border-radius: 12px;
    width: 80%;
    height: 40%;
    margin: auto;
    margin-top: 1.8em;
    border: 0.1em solid;
}

.box {
    width: 100%;
    height: 100%;
    border-radius: 14px;
    margin-top: 0.7em;
    border: 0em solid var(--ion-color-dark);
    box-shadow: none;
}

.connection-lines {
    position: relative;
    bottom: 7em;
    left: 34.5%;
}

.connection-line-1 {
    animation: spin 5s infinite linear running;
    border-bottom: 0.15em solid var(--ion-color-matte);
    width: 21%;
    /* unpause the animation on hover */
    &:hover {
        animation-play-state: paused;
    }
}

.connection-line-2 {
    animation: spin 9s infinite linear running;
    border-bottom: 0.16em dotted;
    width: 21%;
    /* unpause the animation on hover */
    &:hover {
        animation-play-state: paused;
    }
}

.connection-line-3 {
    animation: spin 7s infinite linear running;
    border-bottom: 0.15em solid;
    width: 21%;
    /* unpause the animation on hover */
    &:hover {
        animation-play-state: paused;
    }
}

.permission {
    border-radius: 12px;
    width: 60%;
    height: 90%;
    margin: auto;
    margin-top: 1em;
    margin-left: 1.5em;
}

.row {
    width: 100%;
}


@media screen and (max-width: 600px) {
    .app {
        width: 80%;
        height: 40%;
        margin-top: 0.8em;
    }

    .box {
        width: 100%;
        overflow-x: auto;
    }

    .connection-lines {
        bottom: 7em;
        left: 70%;
    }

    .connection-line-1, .connection-line-2, .connection-line-3 {
        width: 40.5%;
    }

    .permission {
        margin-top: 0.2em;
        height: 99%;
        width: 63%;
    }

    .row {
        width: 200%;
    }
}
</style>