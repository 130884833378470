import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import LandingPage from '../views/LandingPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: LandingPage
  },
  {
    path: '/documentation',
    component: () => import('../views/DocumentationPage.vue')
  },
  {
    path: '/about',
    component: () => import('../views/AboutPage.vue')
  },
  {
    path: '/pricing',
    component: () => import('../views/PricingPage.vue')
  },
  {
    path: '/terms',
    component: () => import('../views/TermsPage.vue')
  },
  {
    path: '/privacy',
    component: () => import('../views/PrivacyPage.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
